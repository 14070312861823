import { API } from "../../../constans/Url"
import { apiClient } from "../../../helper"


const get_ListStatusEmployee = (params, successCallabck, errorCallback) => {
  apiClient(true).get(API.STATUS_EMPLOYEE.LIST, params).then(resp => {
      if (resp.status === 200) {
          successCallabck(resp.data)
      }else {
          errorCallback(resp.data)
      }
  }).catch(err => {
      errorCallback(err.data)
  })
}

const post_SaveStatusEmployee = (params, successCallabck, errorCallback) => {
  apiClient(true).post(API.STATUS_EMPLOYEE.SAVE, params).then(resp => {
      if (resp.status === 200 || resp.status === 201) {
          successCallabck(resp.data)
      }else {
          errorCallback(resp.data)
      }
  }).catch(err => {
      errorCallback(err.data)
  })
}

export {
  get_ListStatusEmployee,
  post_SaveStatusEmployee
}